import { useBreakPointUp } from '@/@crema/hooks/useBreakPointUp';
import { isValidArray } from '@/@crema/utility/utils';
import ReactLazyLoad from '@/shared/components/ReactLazyLoad';
import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { Box, Container, makeStyles } from '@material-ui/core';
import { useCallback, useRef } from 'react';
import ReactSlick from 'react-slick';
import SectionItem from '../SectionItem';
import SliderHeader from './SliderHeader';

const useStyles = makeStyles((theme) => ({
  horizontalSlider: {
    padding: theme.spacing(2, 0)
  },
  horizontalSliderSlick: {
    '& .slick-disabled': {
      opacity: 0.5,
      cursor: 'not-allowed'
    },
    '& .slick-list': {
      overflowX: 'scroll',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      margin: '0 -6px',

      [theme.breakpoints.up('sm')]: {
        margin: '0 -8px'
      }
    }
  }
}));

export default function HorizontalSlider({
  slug,
  data,
  showViewAll,
  item_list_id,
  item_list_name,
  location_id,
  index
}) {
  const sliderRef = useRef();
  const classes = useStyles();
  const isSmUp = useBreakPointUp('sm');
  const isMdUp = useBreakPointUp('md');

  const slidesToShowDeskTop = data.desktop_items_per_slide
    ? Number(data.desktop_items_per_slide)
    : 8;
  const slidesToShowTablet = data.tablet_items_per_slide
    ? Number(data.tablet_items_per_slide)
    : 4.5;
  const slidesToShowMobile = data.desktop_items_per_slide
    ? Number(data.mobile_items_per_slide)
    : 2.5;

  let slidesToShow = slidesToShowMobile;
  if (isMdUp) {
    slidesToShow = slidesToShowDeskTop;
  } else if (isSmUp) {
    slidesToShow = slidesToShowTablet;
  }

  const backgroundColor = data?.color;

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const ratio = data?.ratio || ASPECT_RATIO['3-4'];
  const showNav = data.collections.length > slidesToShow;

  const renderCollections = useCallback(() => {
    if (!isValidArray(data.collections)) {
      return null;
    }

    const SETTINGS = {
      dots: false,
      arrows: false,
      autoplay: false,
      infinite: false,
      swipeToSlide: false,
      draggable: false,
      swipe: false,
      lazyLoad: false,
      slidesToShow: slidesToShowDeskTop,
      slidesToScroll: slidesToShowDeskTop,
      responsive: [
        {
          breakpoint: 959, // include 960px and up
          settings: {
            slidesToShow: slidesToShowTablet,
            slidesToScroll: Math.floor(slidesToShowTablet),
            swipeToSlide: false,
            draggable: false,
            swipe: false,
            infinite: false,
            lazyLoad: false,
            speed: 0,
            touchThreshold: 0,
            useCSS: true
          }
        },
        {
          breakpoint: 599, // include 600px and up
          settings: {
            slidesToShow: slidesToShowMobile,
            slidesToScroll: Math.floor(slidesToShowMobile),
            swipeToSlide: false,
            draggable: false,
            swipe: false,
            infinite: false,
            lazyLoad: false,
            speed: 0,
            touchThreshold: 0,
            useCSS: true
          }
        }
      ]
    };

    return (
      <ReactSlick ref={sliderRef} className={classes.horizontalSliderSlick} {...SETTINGS}>
        {data.collections.map((item, i) => {
          return (
            <SectionItem
              key={`${item.id}-${i}`}
              index={i}
              variant={item?._link}
              data={item}
              ratio={ratio}
              item_list_id={item_list_id}
              item_list_name={item_list_name}
              location_id={location_id}
            />
          );
        })}
      </ReactSlick>
    );
  }, [
    classes.horizontalSliderSlick,
    data.collections,
    item_list_id,
    item_list_name,
    location_id,
    ratio,
    slidesToShowDeskTop,
    slidesToShowMobile,
    slidesToShowTablet
  ]);

  const renderContent = useCallback(() => {
    return (
      <Box
        className={classes.horizontalSlider}
        style={backgroundColor ? { backgroundColor } : {}}
        name={item_list_id}>
        <Container>
          <SliderHeader
            title={data.title}
            slug={data.slug}
            showViewAll={showViewAll}
            showNav={showNav}
            onClickNext={next}
            onClickPrevious={previous}
          />

          {renderCollections()}
        </Container>
      </Box>
    );
  }, [
    backgroundColor,
    classes.horizontalSlider,
    data.slug,
    data.title,
    item_list_id,
    renderCollections,
    showNav,
    showViewAll
  ]);

  if (!data?.code || !isValidArray(data?.collections)) return null;

  if (index <= 1) {
    return renderContent();
  }

  return (
    <ReactLazyLoad height={320} offset={320}>
      {renderContent()}
    </ReactLazyLoad>
  );
}
