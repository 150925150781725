import ButtonLink from '@/@crema/core/Button/ButtonLink';
import AnalyticsService from '@/@crema/services/AnalyticsService';
import { isValidString } from '@/@crema/utility/utils';
import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { getCMSPromotion } from '@/shared/helpers/cms';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import NextImage from '../ImageOptimized/NextImage';

const useStyles = makeStyles((theme) => ({
  promotionContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: theme.spacing(5),
    '& h6': {
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold
    }
  },
  promotionImg: {
    '& img': {
      borderRadius: 0
    }
  },
  content: {
    minHeight: 190,
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      minHeight: 170
    }
  },
  name: {
    ...theme.typography.h4,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down(425)]: {
      ...theme.typography.h6,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  desc: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.body2
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'end'
  },
  btnMore: {
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}));

const PromotionItem = ({
  data,
  item_list_id,
  item_list_name,
  location_id,
  index,
  ratio,
  ...nest
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const {
    id,
    name_slug,
    url,
    name,
    code,
    short_description,
    mobile_image,
    tablet_image,
    desktop_image
  } = getCMSPromotion(data);

  const onClick = useCallback(() => {
    AnalyticsService.select_promotion({
      item_id: name_slug,
      item_name: name,
      index,
      item_list_id,
      item_list_name,
      location_id
    });
  }, [index, item_list_id, item_list_name, location_id, name, name_slug]);

  return (
    <ButtonLink
      {...nest}
      aria-label={name}
      data-code={code}
      data-ratio={ratio}
      href={url}
      className={classes.promotionContainer}
      onClick={onClick}>
      <NextImage
        src={{
          desktop_image: { path: desktop_image },
          tablet_image: { path: tablet_image },
          mobile_image: { path: mobile_image }
        }}
        classNameContainer={classes.promotionImg}
        alt={name}
        ratio={ratio || ASPECT_RATIO['16-9']}
        borderRadius={0}
      />
      <div className={classes.content}>
        <Box display="flex" justifyContent="space-between" flexDirection="column">
          {isValidString(name) && (
            <Typography variant="h4" className={clsx(classes.name, 'line-clamp-2')}>
              {name}
            </Typography>
          )}
          {isValidString(short_description) && (
            <Typography variant="body1" className={clsx(classes.desc, 'line-clamp-2')}>
              {short_description}
            </Typography>
          )}
        </Box>
        <div className={classes.footer}>
          <Button color="primary" variant="contained" className={classes.btnMore}>
            {formatMessage({ id: 'common.readMore' })}
          </Button>
        </div>
      </div>
    </ButtonLink>
  );
};

export default memo(PromotionItem);
