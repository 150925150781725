import { getOriginalTitle, isValidObject, isValidString } from '@/@crema/utility/utils';
import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { Button, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { memo, useRef } from 'react';
import NextImage from '../../ImageOptimized/NextImage';

const useStyles = makeStyles((theme) => ({
  carouselItem: {
    borderRadius: 20,
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'left',
    padding: 24,

    [theme.breakpoints.up('md')]: {
      padding: 32,
      textAlign: 'left',
      width: '60%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      flex: 1
    }
  },
  title: {
    ...theme.typography.h2,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(6),

    '& a': {
      color: theme.palette.primary.main
    },

    [theme.breakpoints.down('md')]: {
      ...theme.typography.h3,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  description: {
    marginBottom: theme.spacing(4)
  }
}));

const CarouselItem = ({
  slug,
  title,
  index,
  code,
  description,
  image,
  cta_text,
  cta_link,
  cta_attribute,
  ratio,
  onClick,
  onClickCTA
}) => {
  const dragRef = useRef(false);
  const classes = useStyles();

  const _title = getOriginalTitle(title);

  const onDragStart = (e) => {
    dragRef.current = true;
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
  };

  const _onClick = (e) => {
    onClick({ title: _title, index, code });

    if (!dragRef.current) return;
    dragRef.current = false;
    e.preventDefault();
  };

  const _onClickCTA = () => {
    if (!isValidString(cta_link)) return;
    if (isValidObject(cta_attribute)) {
      onClickCTA(cta_attribute);
    }
  };

  const content = (
    <div>
      <NextImage
        borderRadius={20}
        src={image?.path}
        alt={image?.meta?.title || title}
        skeleton
        ratio={ratio}
      />

      {/* {(title || description || cta_link) && <div className="info-overlay" />} */}
      <div className="info">
        <div className={classes.content}>
          <div>
            {isValidString(description) && (
              <Typography className={clsx(classes.description, 'line-clamp-1')}>
                {description}
              </Typography>
            )}
            {isValidString(title) && (
              <div
                className={clsx(classes.title, 'line-clamp-2')}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
          </div>
          {cta_text && cta_link && (
            <div className="pt-2">
              <Button
                className="info-button"
                variant="contained"
                color="primary"
                disableElevation
                fullWidth={false}
                onClick={_onClickCTA}>
                {cta_text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (isValidString(cta_link)) {
    return (
      <Link href={cta_link} passHref>
        <a
          href="replace"
          onDragStart={onDragStart}
          onDrop={onDrop}
          onClick={_onClick}
          aria-label={_title || ''}
          name="top_carousel">
          {content}
        </a>
      </Link>
    );
  }

  return (
    <div className={classes.carouselItem} data-slug={slug}>
      {content}
    </div>
  );
};

CarouselItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  cta_text: PropTypes.string,
  cta_link: PropTypes.string,
  ratio: PropTypes.string,
  onClick: PropTypes.func,
  onClickCTA: PropTypes.func
};

CarouselItem.defaultProps = {
  ratio: ASPECT_RATIO['4-1'],
  onClick: () => {},
  onClickCTA: () => {}
};

export default memo(CarouselItem);
