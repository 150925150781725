import { useBreakPointDown } from '@/@crema/hooks/useBreakPointDown';
import { isValidArray } from '@/@crema/utility/utils';
import ReactLazyLoad from '@/shared/components/ReactLazyLoad';
import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { GRID_MAPPING } from '@/shared/constants/GridConst';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import SectionItem from '../SectionItem';
import SliderHeader from '../Slider/SliderHeader';

const useStyles = makeStyles((theme) => ({
  block: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),

    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8)
    }
  }
}));

export default function Block({
  slug,
  data,
  showViewAll = true,
  item_list_id,
  item_list_name,
  location_id
}) {
  const classes = useStyles();
  const isMobile = useBreakPointDown('sm');
  const ratio = data?.ratio || ASPECT_RATIO['3-4'];

  if (!data?.code) {
    return null;
  }

  const renderContent = () => {
    return (
      <Box className={classes.block} name={location_id}>
        <Container>
          <SliderHeader
            title={data.title}
            slug={data.slug}
            showViewAll={showViewAll}
            showNav={false}
          />

          {isValidArray(data?.collections) && (
            <Grid container spacing={isMobile ? 3 : 4}>
              {data.collections.map((item, index) => {
                let xs = GRID_MAPPING[data?.mobile_items_per_slide] || 12;
                let sm = GRID_MAPPING[data?.tablet_items_per_slide] || 6;
                let md = GRID_MAPPING[data?.desktop_items_per_slide] || 4;

                // banner has double size of item
                if (item._link === 'banner') {
                  xs *= 2;
                  sm *= 2;
                  md *= 2;
                }

                return (
                  <Grid key={`${item?.code}-${index}`} item xs={xs} sm={sm} md={md}>
                    <SectionItem
                      index={index}
                      variant={item._link}
                      data={item}
                      isMobile={isMobile}
                      ratio={ratio}
                      hasPadding={false}
                      item_list_id={item_list_id}
                      item_list_name={item_list_name}
                      location_id={location_id}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Container>
      </Box>
    );
  };

  return (
    <ReactLazyLoad height={320} offset={320}>
      {renderContent()}
    </ReactLazyLoad>
  );
}
