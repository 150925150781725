import AnalyticsService from '@/@crema/services/AnalyticsService';
import { isValidArray } from '@/@crema/utility/utils';
import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { memo, useCallback } from 'react';
import ButtonLink from '../Button/ButtonLink';
import NextImage from '../ImageOptimized/NextImage';

const useStyles = makeStyles((theme) => ({
  bannerItem: {
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    top: 24,
    left: 24,
    maxWidth: '70%'
  }
}));

function BannerItem({ index, data, item_list_id, item_list_name, location_id }) {
  const classes = useStyles();

  const key = data?.key || '';

  // TODO: implement list of sliders later (slider in item)
  const slider = isValidArray(data?.slider) ? data?.slider : [];
  const item = slider[0];
  const title = item?.value?.title || '';
  const description = item?.value?.description || '';
  const src = item?.value?.image?.path || '';
  const cta_link = item?.value?.cta_link || '';

  const onClick = useCallback(() => {
    AnalyticsService.select_promotion({
      item_id: key,
      item_name: title,
      index,
      item_list_id,
      item_list_name,
      location_id
    });
  }, [index, item_list_id, item_list_name, key, location_id, title]);

  if (!data) {
    return null;
  }

  return (
    <ButtonLink
      aria-label={title}
      href={cta_link}
      name="game-banner-item"
      data-key={key}
      onClick={onClick}>
      <Box className={classes.bannerItem}>
        <NextImage borderRadius={20} src={src} alt={title} skeleton ratio={ASPECT_RATIO['2-1']} />
        <Box className={classes.overlay}>
          <Typography variant="h5" color="textPrimary" className="bold" gutterBottom>
            {title}
          </Typography>
          <Typography color="primary">{description}</Typography>
        </Box>
      </Box>
    </ButtonLink>
  );
}

export default memo(BannerItem);
