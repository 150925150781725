import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { useBreakPointDown } from './useBreakPointDown';

export const useCarouselRatio = () => {
  const isMobile = useBreakPointDown('xs');
  const isTablet = useBreakPointDown('sm');
  const ratio = isMobile
    ? ASPECT_RATIO['1-1']
    : isTablet
    ? ASPECT_RATIO['4-3']
    : ASPECT_RATIO['4-1'];

  return {
    ratio,
    isMobile,
    isTablet
  };
};
