import AnalyticsService from '@/@crema/services/AnalyticsService';
import { onShowGameDetailForPlay } from '@/redux/actions';
import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { getCMSGame } from '@/shared/helpers/cms';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import GameRestricted from '../GameRestricted';
import NextImage from '../ImageOptimized/NextImage';
import GameItemOverlay from './GameItemOverlay';

const useStyles = makeStyles((theme) => ({
  gameItem: {
    position: 'relative',
    backgroundColor: ({ contrast }) =>
      contrast ? theme.palette.background.contrast : theme.palette.background.paper,
    width: '100%',
    height: '100%',
    borderRadius: 20,
    overflow: 'hidden',
    zIndex: 1 /* overflow/border-radius mask bug-thingy */,
    cursor: 'pointer'

    // '@media (hover: hover)': {
    //   '&:hover': {
    //     '& $playLink, $demoLink, $favorite': {
    //       opacity: 1
    //     }
    //   }
    // }
  },
  maintenance: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    background: 'url("/images/game-maintenance.png") no-repeat 0 50%',
    backgroundSize: 'contain',
    backgroundColor: theme.palette.background.overlay,
    cursor: 'pointer',
    zIndex: 2
  },
  disabled: {
    cursor: 'not-allowed',
    pointerEvents: 'none !important'
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundColor: 'unset'
  }
}));

const GameItem = ({ data, ratio, contrast, index, item_list_id, item_list_name, location_id }) => {
  const classes = useStyles({ contrast });
  const dispatch = useDispatch();

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const gameData = getCMSGame(data);

  const {
    name,
    code,
    provider_name,
    maintenance,
    desktop_image,
    tablet_image,
    mobile_image,
    alt,
    country_restricted
  } = gameData;

  const _onClick = useCallback(
    (event) => {
      event.preventDefault();
      if (country_restricted) return;
      dispatch(onShowGameDetailForPlay(gameData));
      AnalyticsService.select_item({
        item_id: code,
        item_name: name,
        item_brand: provider_name,
        index,
        item_list_id,
        item_list_name,
        location_id,
        quantity: 1
      });
    },
    [
      gameData,
      dispatch,
      code,
      name,
      provider_name,
      index,
      item_list_id,
      item_list_name,
      location_id,
      country_restricted
    ]
  );

  const renderContent = useCallback(() => {
    if (!hover) return null;

    return <GameItemOverlay data={gameData} />;
  }, [gameData, hover]);

  const renderGameRestricted = useCallback(() => {
    return (
      <Box className={clsx(classes.gameItem, maintenance ? classes.disabled : '')}>
        <NextImage
          alt={alt}
          src={desktop_image || tablet_image || mobile_image}
          ratio={ratio}
          classNameContainer={classes.image}
        />
      </Box>
    );
  }, [
    alt,
    classes.disabled,
    classes.gameItem,
    classes.image,
    desktop_image,
    maintenance,
    mobile_image,
    ratio,
    tablet_image
  ]);

  const renderGameContainer = useCallback(() => {
    return (
      <Box
        className={clsx(classes.gameItem, maintenance ? classes.disabled : '')}
        onClick={_onClick}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}>
        <NextImage
          alt={alt}
          src={desktop_image || tablet_image || mobile_image}
          ratio={ratio}
          classNameContainer={classes.image}
        />
        {renderContent()}
      </Box>
    );
  }, [
    _onClick,
    alt,
    classes.disabled,
    classes.gameItem,
    classes.image,
    desktop_image,
    maintenance,
    mobile_image,
    ratio,
    renderContent,
    tablet_image
  ]);

  const renderGameMaintenance = useCallback(() => {
    return (
      <Box className={clsx(classes.gameItem, maintenance ? classes.disabled : '')}>
        <Box className={classes.maintenance} />
        <NextImage
          alt={alt}
          src={desktop_image || tablet_image || mobile_image}
          ratio={ratio}
          classNameContainer={classes.image}
        />
      </Box>
    );
  }, [
    alt,
    classes.disabled,
    classes.gameItem,
    classes.image,
    classes.maintenance,
    desktop_image,
    maintenance,
    mobile_image,
    ratio,
    tablet_image
  ]);

  if (country_restricted) {
    return <GameRestricted restricted>{renderGameRestricted()}</GameRestricted>;
  }

  if (maintenance) {
    return renderGameMaintenance();
  }

  return renderGameContainer();
};

GameItem.propTypes = {
  data: PropTypes.object.isRequired,
  ratio: PropTypes.string
};

GameItem.defaultProps = {
  ratio: ASPECT_RATIO['3-4']
};

export default React.memo(GameItem);
