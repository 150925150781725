import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  carousel: {
    '& .slick-slide': {
      overflow: 'hidden',
      position: 'relative',
      '&.slick-active': {
        zIndex: 1
      },

      '& .info-overlay': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        transform: 'rotate(14deg) translate(-60px, 179px)',
        backgroundImage: 'linear-gradient(transparent, #000)',
        [theme.breakpoints.down('sm')]: {
          top: 'unset',
          height: '40%',
          transform: 'unset'
        }
      },

      '& .info': {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      }
    },
    '& .slick-dots': {
      position: 'relative',
      display: 'flex !important',
      justifyContent: 'center',
      margin: '28px 0 4px 0',
      bottom: 0,

      '& li': {
        width: 'auto',
        height: 'auto',
        marginLeft: 8,
        marginRight: 8
      },

      '& .slick-dot': {
        width: 12,
        height: 12,
        borderRadius: 12,
        backgroundColor: theme.palette.text.disabled,
        opacity: 1
      },

      '& .slick-active .slick-dot': {
        width: 24,
        height: 12,
        borderRadius: 16,
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        transition: 'width 0.25s ease-in-out'
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        gap: 8,

        '& li': {
          marginLeft: 0,
          marginRight: 0
        },
        '& .slick-dot': {
          width: 20,
          height: 2,
          borderRadius: 4,
          backgroundColor: theme.palette.text.disabled
        },
        '& .slick-active .slick-dot': {
          width: 20,
          height: 2,
          borderRadius: 4,
          backgroundColor: theme.palette.text.primary,
          opacity: 1
        }
      }
    }
  }
}));

export default useStyles;
