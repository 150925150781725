import AnalyticsService from '@/@crema/services/AnalyticsService';
import { onSetFavoriteGames } from '@/redux/actions';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import IconButton from '../Button/IconButton';
import Icon from '../Icon';

let lastAllClicked = 0;
const threshold = 1000;

const useStyles = makeStyles((theme) => ({
  favoriteButton: {
    zIndex: 3,
    padding: theme.spacing(2)
  },
  favorited: {
    color: theme.palette.error.main
  }
}));

function ToggleFavorite({
  id,
  favorited,
  code,
  name,
  provider_name,
  game_type,
  product_type_name
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [isFavorited, setIsFavorite] = useState(false);
  const lastClickedRef = useRef(0);

  // logd('ToggleFavorite', { id, favorited, isFavorited });

  useEffect(() => {
    setIsFavorite(favorited);
  }, [favorited]);

  const _onClick = useCallback(
    async (event) => {
      try {
        event.preventDefault();
        event.stopPropagation();

        if (
          (lastClickedRef.current > 0 &&
            new Date().getTime() - lastClickedRef.current < threshold) ||
          (lastAllClicked > 0 && new Date().getTime() - lastAllClicked < threshold)
        ) {
          return;
        }

        lastClickedRef.current = new Date().getTime();
        lastAllClicked = new Date().getTime();

        setIsFavorite((prev) => !prev);

        const isExist = await dispatch(onSetFavoriteGames(id));

        if (typeof isExist === 'boolean' && !isExist) {
          const data = {
            item_id: code || '',
            item_name: name || '',
            item_brand: provider_name || '',
            item_category: game_type || '',
            item_category2: product_type_name || '',
            quantity: 1
          };
          AnalyticsService.add_to_wishlist(data);
        }
      } catch (error) {
        // logd('ToggleFavorite.error');
      }
    },
    [code, dispatch, game_type, id, name, product_type_name, provider_name]
  );

  return (
    <Tooltip
      title={formatMessage({ id: isFavorited ? 'game.unfavorite' : 'game.favorite' })}
      enterDelay={500}
      disableTouchListener
      enterTouchDelay={0}>
      <Box>
        <IconButton
          className={clsx(classes.favoriteButton, isFavorited ? classes.favorited : null)}
          onClick={_onClick}
          aria-label="favorite"
          name="favorite"
          color={isFavorited ? 'secondary' : 'inherit'}>
          <Icon name={isFavorited ? 'heart-filled' : 'heart'} />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

export default memo(ToggleFavorite);
