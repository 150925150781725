import RLL from 'react-lazyload';

/**
 * @param {import('react-lazyload').LazyLoadProps}
 * @returns
 */
export default function ReactLazyLoad({ children, ...nest }) {
  if (typeof window !== 'undefined') {
    return (
      <RLL offset={350} {...nest} once>
        {children}
      </RLL>
    );
  }

  return children;
}
