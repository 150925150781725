import { useCarouselRatio } from '@/@crema/hooks/useCarouselRatio';
import AnalyticsService from '@/@crema/services/AnalyticsService';
import { logd } from '@/@crema/utility/Logging';
import { isValidArray } from '@/@crema/utility/utils';
import { onGetCouponGame, showPopup } from '@/redux/actions';
import { useIsLoggedIn } from '@/redux/reducers/Auth';
import { isDev } from '@/shared/constants/AppConst';
import { POPUP_NO_AUTH } from '@/shared/constants/PopupConst';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ReactSlick from 'react-slick';
import CarouselItem from './CarouselItem';
import useStyles from './index.style';

const customDot = (i) => {
  return <div key={`slick-dot-${i}`} className="slick-dot" />;
};

const SETTINGS = {
  customPaging: customDot,
  dots: true,
  fade: true,
  lazyLoad: false,
  infinite: true,
  speed: 1000,
  autoplay: !isDev,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
};

const Carousel = ({ slug, name, data }) => {
  const classes = useStyles();
  const { ratio, isTablet, isMobile } = useCarouselRatio();
  const isLoggedIn = useIsLoggedIn();
  const dispatch = useDispatch();

  logd('Carousel.data', data);

  const location_id = `${slug}_top`;
  const item_list_id = `${slug}_carousel`;
  const item_list_name = `${name} Carousel`;
  // const page_type = `${slug}_page`;

  const submitApi = useCallback(
    async (method, url) => {
      await dispatch(isLoggedIn ? onGetCouponGame(method, url) : showPopup(POPUP_NO_AUTH.LOGIN));
    },
    [dispatch, isLoggedIn]
  );

  const onClickCTA = useCallback(
    (data) => {
      switch (data['data-type']) {
        case 'api':
          return submitApi(data['data-api-method'], data['data-api-url']);
        default:
          return;
      }
    },
    [submitApi]
  );

  const onClick = useCallback(
    (item) => {
      AnalyticsService.select_promotion({
        item_id: item.code,
        item_name: item.title,
        index: item.index,
        item_list_id,
        item_list_name,
        location_id
      });
    },
    [item_list_id, item_list_name, location_id]
  );

  if (!isValidArray(data)) return null;

  const settings = isMobile ? { ...SETTINGS, fade: false, centerMode: true } : SETTINGS;

  return (
    <div name={item_list_id}>
      <Container>
        <ReactSlick className={classes.carousel} {...settings}>
          {data.map((item, index) => {
            const _key = `${item_list_id}-${index}`;
            return (
              <CarouselItem
                key={_key}
                slug={_key}
                index={index}
                code={item?.value?.code}
                title={item?.value?.title}
                description={item?.value?.description}
                image={isTablet ? item?.value?.mobile_image : item?.value?.image}
                cta_text={item?.value?.cta_text}
                cta_link={item?.value?.cta_link}
                cta_attribute={item?.value?.cta_attribute}
                ratio={ratio}
                onClickCTA={onClickCTA}
                onClick={onClick}
              />
            );
          })}
        </ReactSlick>
      </Container>
    </div>
  );
};

Carousel.propTypes = {
  data: PropTypes.array
};

export default Carousel;
