import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo } from 'react';
import BannerItem from '../../Banner/BannerItem';
import GameItem from '../../Game/GameItem';
import GameVendorItem from '../../Game/GameVendorItem';
import PromotionItem from '../../Promotion/PromotionItem';

const useStyles = makeStyles((theme) => ({
  sectionItem: {
    height: '100%',
    paddingLeft: theme.spacing(3) / 2,
    paddingRight: theme.spacing(3) / 2,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  sectionItemNoPadding: {
    height: '100%',
    padding: 0
  }
}));

const SectionItem = ({ variant, hasPadding = true, tabIndex, style, ...rest }) => {
  const classes = useStyles();

  const renderItemByVariant = () => {
    switch (variant) {
      case 'game':
        return <GameItem {...rest} />;
      case 'game_vendor':
        return <GameVendorItem {...rest} />;
      case 'promotion':
        return <PromotionItem {...rest} />;
      case 'banner':
        return <BannerItem {...rest} />;
      default:
        return null;
    }
  };

  return (
    <Box className={hasPadding ? classes.sectionItem : classes.sectionItemNoPadding}>
      {renderItemByVariant()}
    </Box>
  );
};

SectionItem.propTypes = {
  variant: PropTypes.oneOf(['game', 'game_vendor', 'banner', 'promotion'])
};

SectionItem.defaultProps = {
  variant: 'game'
};

export default memo(SectionItem);
