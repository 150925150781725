import IconButton from '@/@crema/core/Button/IconButton';
import { useBreakPointDown } from '@/@crema/hooks/useBreakPointDown';
import IntlMessages from '@/@crema/utility/IntlMessages';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ButtonLink from '../../../Button/ButtonLink';
import Icon from '../../../Icon';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '4px 0',
    minHeight: 40,
    marginBottom: theme.spacing(1)
  },
  slideBtn: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export default function SliderHeader({
  title,
  slug,
  showViewAll = false,
  showNav = false,
  onClickNext,
  onClickPrevious
}) {
  const classes = useStyles();
  const isSm = useBreakPointDown('sm');

  if (isSm && !showViewAll) {
    if ((slug && title) || title) {
      return (
        <Box className={classes.header}>
          <Box flex={1} display="flex" alignItems="center">
            {slug ? (
              <ButtonLink href={slug}>
                <Typography variant="h4" color="textPrimary" className="bold">
                  {title}
                </Typography>
              </ButtonLink>
            ) : (
              <Typography variant="h4" color="textPrimary" className="bold">
                {title}
              </Typography>
            )}
          </Box>
        </Box>
      );
    }
    return null;
  }

  return (
    <Box className={classes.header}>
      <Box flex={1} display="flex" alignItems="center">
        {slug ? (
          <ButtonLink href={slug}>
            <Typography variant="h5" color="textPrimary">
              {title}
            </Typography>
          </ButtonLink>
        ) : (
          <Typography variant="h5" color="textPrimary">
            {title}
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {slug && showViewAll && (
          <ButtonLink href={slug}>
            <Typography variant="body2" color="primary">
              <IntlMessages id="common.showAll" />
            </Typography>
          </ButtonLink>
        )}
        {showNav && (
          <>
            <IconButton
              variant="outlined"
              size="small"
              title="common.previous"
              onClick={onClickPrevious}
              className={classes.slideBtn}>
              <Icon name="back" />
            </IconButton>
            <IconButton
              variant="outlined"
              size="small"
              title="common.next"
              onClick={onClickNext}
              className={classes.slideBtn}>
              <Icon name="forward" />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
}
