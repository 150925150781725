import ButtonLink from '@/@crema/core/Button/ButtonLink';
import NextImage from '@/@crema/core/ImageOptimized/NextImage';
import AnalyticsService from '@/@crema/services/AnalyticsService';
import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { getCMSGameVendor } from '@/shared/helpers/cms';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  image: {
    padding: `${theme.spacing(3)}px !important`
  }
}));

const SectionItemGameVendor = ({ index, data, item_list_id, item_list_name, location_id }) => {
  const classes = useStyles();
  const { name, code, icon, href } = getCMSGameVendor(data);

  const onClick = useCallback(() => {
    AnalyticsService.select_promotion({
      item_id: code,
      item_name: name,
      index,
      item_list_id,
      item_list_name,
      location_id
    });
  }, [index, item_list_id, item_list_name, location_id, code, name]);

  return (
    <ButtonLink aria-label={name} href={href} onClick={onClick}>
      <NextImage
        src={icon}
        alt={name}
        skeleton
        ratio={ASPECT_RATIO['4-3']}
        borderRadius={20}
        className={classes.image}
        objectFit="contain"
      />
    </ButtonLink>
  );
};

SectionItemGameVendor.propTypes = {
  data: PropTypes.object.isRequired
};

export default memo(SectionItemGameVendor);
