import IntlMessages from '@/@crema/utility/IntlMessages';
import { isValidArray } from '@/@crema/utility/utils';
import { useEnableFavoriteGame } from '@/redux/reducers/App';
import { useFavoriteGames } from '@/redux/reducers/Game';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ButtonLink from '../Button/ButtonLink';
import ToggleFavorite from '../GameAssets/ToggleFavorite';

const useStyles = makeStyles((theme) => ({
  playLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.overlay,
    zIndex: 2,
    // opacity: 0,
    // transition: 'opacity 0.5s ease',

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  favorite: {
    position: 'absolute',
    top: 2,
    right: 2,
    bottom: 0,
    left: 0,
    // opacity: 0,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  playIcon: {
    background: 'url("/svg/play.svg") no-repeat 50%',
    backgroundSize: 36,
    width: 36,
    height: 36,
    position: 'absolute',
    inset: 0,
    margin: 'auto'
  },
  demoLink: {
    position: 'absolute',
    top: 8,
    left: 0,
    right: 0,
    justifyContent: 'center',
    minHeight: 28,
    zIndex: 3,
    // opacity: 0,
    // transition: 'opacity 0.5s ease',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '6px 12px',
    [theme.breakpoints.down('sm')]: {
      padding: '4px 8px'
    }
  }
}));

const GameItemOverlay = ({ data }) => {
  const classes = useStyles();

  const enableFavorite = useEnableFavoriteGame();
  const favoriteGames = useFavoriteGames();

  const {
    id,
    name,
    code,
    provider,
    provider_name,
    has_trial,
    url,
    demo_url,
    game_type,
    product_type_name
  } = data;

  let favorited = false;
  if (enableFavorite && isValidArray(favoriteGames) && id) {
    favorited = favoriteGames.includes(id);
  }

  return (
    <>
      <ButtonLink aria-label={`Play game ${name}`} className={classes.playLink} href={url}>
        <Box className={classes.wrapper}>
          <Box className={classes.playIcon} />
          {(name || provider_name) && (
            <Box>
              <Typography component="div" variant="subtitle2" color="textPrimary" noWrap>
                {name}
              </Typography>
              <Typography variant="caption" color="textPrimary">
                {provider_name}
              </Typography>
            </Box>
          )}
        </Box>
      </ButtonLink>
      <Box className={classes.demoLink}>
        {has_trial && (
          <Button
            component={ButtonLink}
            variant="outlined"
            size="small"
            aria-label={`Play demo ${name}`}
            href={demo_url}
            name="game-item-play-demo"
            data-code={code}
            data-provider={provider}>
            <IntlMessages id="common.demo" />
          </Button>
        )}
      </Box>
      {enableFavorite && (
        <Box className={classes.favorite}>
          <Box display="flex" justifyContent="flex-end">
            <ToggleFavorite
              id={id}
              favorited={favorited}
              code={code}
              name={name}
              provider_name={provider_name}
              game_type={game_type}
              product_type_name={product_type_name}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

GameItemOverlay.propTypes = {
  data: PropTypes.object.isRequired
};

export default GameItemOverlay;
