import { getOriginalTitle, isValidArray } from '@/@crema/utility/utils';
import Block from './Block';
import Slider from './Slider';

export default function Sections({ slug, data }) {
  if (!isValidArray(data)) {
    return null;
  }

  return data.map((item, index) => {
    const item_list_id = item?.value?.code;
    const item_list_name = getOriginalTitle(item?.value?.title);
    const location_id = `${slug}_${item_list_id}`;
    switch (item?.value?.display) {
      case 'slider':
        return (
          <Slider
            key={index}
            slug={slug}
            data={item?.value}
            showViewAll
            item_list_id={item_list_id}
            item_list_name={item_list_name}
            location_id={location_id}
            index={index}
          />
        );
      case 'block':
        return (
          <Block
            key={index}
            slug={slug}
            data={item?.value}
            showViewAll={false}
            item_list_id={item_list_id}
            item_list_name={item_list_name}
            location_id={location_id}
          />
        );
      default:
        return null;
    }
  });
}
